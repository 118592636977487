import * as React from 'react';
import Layout from '../components/common/layout';

const NotFoundPage = () => {
    return (
        <Layout>
            <div className="row">
                <div className="col-12 text-center pt-5">
                    <h3 className="lh-2">
                        The page you are looking for could not be found. <br />
                        Please check different docs.
                    </h3>
                </div>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
